import React, { useEffect, useState } from "react";
import moment from "moment";

// External components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Translation
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";

// APIs
import getSingleDataBreach from "api/dataBreach/getSingleDataBreach";
import { useMaterialUIController } from "context";
import dataBreachManaged from "api/dataBreach/dataBreachManaged";

const DataBreachDetails = () => {
  const [data, setData] = useState(null);
  const { t } = useTranslation();
  const { id } = useParams();
  const [controller, _] = useMaterialUIController();
  const { user } = controller;

  const getData = async () => {
    try {
      const response = await getSingleDataBreach(id);
      setData(response);
    } catch (e) {
      console.log("error --->", e);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  function getResults() {
    return data.results.map((res, index) => {
      if (res.rows && res.rows.length > 0) {
        return res.rows.map((row, indexRow) => (
          <div
            key={`data-breach-result-${index.toString()}-${indexRow.toString()}`}
            className="boxes-border-radius"
            style={{ backgroundColor: "#fff", padding: "15px", marginBottom: "30px" }}
          >
            <div className="data-breach-details-intro-line">#{(indexRow + 1).toString()}</div>
            {Object.keys(row).map((element) => {
              if (element && element.length > 1 && row[element]) {
                return (
                  <div
                    key={`data-breach-result-${index.toString()}-${indexRow.toString()}-${element}`}
                    style={{ fontSize: "16px" }}
                  >
                    <span>{t(`dataBreach.labels.${element}`)}:</span>{" "}
                    <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                      {Array.isArray(row[element]) ? row[element].join("/") : row[element]}
                    </span>
                  </div>
                );
              }
              return null;
            })}
          </div>
        ));
      }
      return null;
    });
  }

  function getNumberOfAttacks() {
    let attacksNumber = 0;
    if (data.results.length > 0) {
      data.results.forEach((res) => {
        if (res.rows && res.rows.length > 0) {
          attacksNumber += res.rows.length;
        }
      });
    }
    return `(${attacksNumber})`;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* back to the results */}
      <div style={{ marginBottom: "15px" }}>
        <Link className="back-to-results-link" to="/databreaches/">
          {t("dataBreach.back_to_results")}
        </Link>
      </div>

      {/* Title */}
      <h2 className="page-title">{data && data.shortDescription}</h2>

      {/* Box */}
      <div className="boxes-border-radius" style={{ background: "white", padding: "15px" }}>
        {data ? (
          <>
            <dl style={{ fontSize: "16px" }}>
              <dt style={{ fontWeight: "600" }}>{t("dataBreach.name")}</dt>
              <dd style={{ marginBottom: "15px" }}>{data.threatActor?.name || "N/A"}</dd>

              <dt style={{ fontWeight: "600" }}>{t("dataBreach.incident_date")}</dt>
              <dd style={{ marginBottom: "15px" }}>
                {moment(data.incidentDate).format("DD/MM/YYYY")}
              </dd>

              <dt style={{ fontWeight: "600" }}>{t("dataBreach.published_date")}</dt>
              <dd style={{ marginBottom: "15px" }}>
                {moment(data.publishedDate).format("DD/MM/YYYY")}
              </dd>

              <dt style={{ fontWeight: "600" }}>{t("dataBreach.description")}</dt>
              <dd style={{ marginBottom: "15px" }}>{data.description}</dd>

              <dt style={{ fontWeight: "600" }}>{t("dataBreach.intelligence")}</dt>
              <dd style={{ marginBottom: "15px" }}>{data.intelligence}</dd>

              <dt style={{ fontWeight: "600" }}>{t("dataBreach.columns")}</dt>
              <dd style={{ marginBottom: "15px", display: "flex", gap: "5px" }}>
                {data.columns.length > 0
                  ? data.columns.map((item, j) => (
                      <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={j}
                        style={{
                          fontSize: "14px",
                          background: "lightgray",
                          borderRadius: "35px",
                          textAlign: "center",
                          marginBottom: "3px",
                          padding: "1px 5px",
                        }}
                      >
                        {t(`dataBreach.labels.${item}`)}
                      </div>
                    ))
                  : null}
              </dd>

              <dt style={{ fontWeight: "600" }}>{t("dataBreach.whatHappened_title")}</dt>
              <dd style={{ marginBottom: "15px" }}>{t("dataBreach.whatHappened_body")}</dd>

              {/* Breach management */}
              {user && user.role && user.role !== "admin" ? (
                <div style={{}}>
                  {!data.managedBy ||
                  (data.managedBy && data.managedBy.length < 1) ||
                  (data.managedBy &&
                    data.managedBy.length > 0 &&
                    user &&
                    user.id &&
                    !data.managedBy.includes(user.id)) ? (
                    <button
                      type="button"
                      className="request-report-button"
                      onClick={() => {
                        if (data.id) {
                          dataBreachManaged(data.id)
                            .then((resManaged) => {
                              if (
                                resManaged &&
                                resManaged.managedBy &&
                                resManaged.managedBy.length > 0
                              ) {
                                const newBreach = { ...data, managedBy: resManaged.managedBy };
                                setData(newBreach);
                              }
                            })
                            .catch((errManaged) => {
                              // eslint-disable-next-line
                              console.log("error setting databreach managed", errManaged);
                            });
                        }
                      }}
                    >
                      {t("dataBreach.manage")}
                    </button>
                  ) : (
                    <div
                      style={{
                        padding: "10px",
                        border: "1px solid green",
                        background: "#e6ffde",
                        color: "#399918",
                        borderRadius: "12px",
                        fontWeight: "600",
                      }}
                    >
                      <span>{t("dataBreach.managed_breach")}</span>
                    </div>
                  )}
                </div>
              ) : null}
            </dl>
          </>
        ) : null}
      </div>
      {data && data.results && data.results.length > 0 ? (
        <div>
          <div className="data-breach-attacks-intro">
            {t("dataBreach.attacks_found")} {getNumberOfAttacks()}
          </div>
          {getResults()}
        </div>
      ) : null}
    </DashboardLayout>
  );
};

export default DataBreachDetails;
