// @mui material components
import { useState, useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
// import getTokens from "api/getTokens";
import getAllUser from "api/users/getAllUser";
import forceRefreshUserMfa from "api/users/forceRefreshUserMfa";
import getRoles from "api/users/getRoles";
import getAllClients from "api/clients/getAllClients";
import editUser from "api/users/editUser";
import addLog from "api/logs/addLog";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MDInput from "components/MDInput";
import { useMaterialUIController } from "context";
import SweetAlert from "react-bootstrap-sweetalert";
import Pagination from "layouts/dashboards/stealers/pagination";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Icons import
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import MDTypography from "components/MDTypography";
import deleteUser from "api/users/deleteUser";

const MIN_CHAR_SEARCH = 1;

function UserList() {
  // eslint-disable-next-line
  const [controller, dispatch] = useMaterialUIController();
  const { user } = controller;
  const [users, setUsers] = useState([]);
  const [editElement, setEditElement] = useState(null);
  const [popup, setPopup] = useState(false);
  const [popupSuccess, setPopupSuccess] = useState(false);
  const [name, setName] = useState("");
  const [shouldReceiveReport, setShouldReceiveReport] = useState(false);
  const [skipMfa, setSkipMfa] = useState(false);
  const [mustChangePassword, setMustChangePassword] = useState(false);
  const [hasMobile, setHasMobile] = useState(false);
  const [mailMessageDisabled, setMailMessageDisabled] = useState(false);
  const [emailFilter, setEmailFilter] = useState("");
  const [roleFilter, setRoleFilter] = useState("");
  const [roles, setRoles] = useState([]);
  const [appUserFilter, setAppUserFilter] = useState("");
  // eslint-disable-next-line
  const [pages, setPages] = useState(0);
  // eslint-disable-next-line
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [orderBy, setOrderBy] = useState("email");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [elementInList, setElementInList] = useState(0);
  const [clients, setClients] = useState([]);
  const [clientsSelected, setClientsSelected] = useState([]);
  const [originalClients, setOriginalClients] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [client, setClient] = useState("");
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [deleteUserPopup, setDeleteUserPopup] = useState(false);
  const [deleteUserPopupSuccess, setDeleteUserPopupSuccess] = useState(false);
  const [refreshUserMfa, setRefreshUserMfa] = useState(null);
  const [genericError, setGenericError] = useState(false);
  const [userEditError, setUserEditError] = useState(false);
  const { t } = useTranslation();

  const validateEmail = (em) =>
    String(em)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

  function getUsers() {
    getAllUser(page, limit, emailFilter, roleFilter, appUserFilter, orderBy)
      .then((res) => {
        if (res.results && res.results.length > 0) {
          if (res.totalPages) {
            setPages(res.totalPages);
          }
          setUsers(res.results);
        }
      })
      // eslint-disable-next-line
      .catch((err) => console.log("error", err));
  }

  function getAllRoles() {
    getRoles()
      .then((res) => {
        if (res && res.length > 0) {
          setRoles(res);
        }
      })
      // eslint-disable-next-line
      .catch((err) => console.log("error while getting roles", err));
  }

  useEffect(() => {
    getUsers();
    getAllRoles();
  }, []);

  useEffect(() => {
    getUsers();
  }, [page, limit, emailFilter, roleFilter, appUserFilter, orderBy]);

  useEffect(() => {
    // const tokens = getTokens();
    getAllClients(1, 1000)
      .then((res) => {
        if (res.results && res.results.length > 0) {
          setClients(res.results);
          setOriginalClients(res.results);
          setAllClients(res.results);
        }
      })
      // eslint-disable-next-line
      .catch((err) => console.log("error", err));
  }, []);

  function getInput(type, editFunction, defaultValue) {
    return (
      <MDInput
        type="input"
        label={type}
        fullWidth
        className={type === "Email" && errorEmail ? "input-error" : ""}
        defaultValue={defaultValue}
        onChange={(e) => {
          if (type === "Email") {
            if (validateEmail(e.target.value)) {
              setErrorEmail(false);
            } else {
              setErrorEmail(true);
            }
            editFunction(e.target.value);
          } else {
            editFunction(e.target.value);
          }
        }}
        style={{ marginTop: "30px" }}
      />
    );
  }

  function getSelect(options, editFunction, defaultValue) {
    return (
      <select
        onChange={(e) => {
          editFunction(e.target.value);
        }}
        defaultValue={defaultValue}
        className="selectDefault"
        style={{
          width: "100%",
          padding: "0.75rem",
          borderRadius: "0.375rem",
          borderColor: "#d2d6da",
          marginTop: "30px",
        }}
      >
        {options.map((el, index) => (
          <option value={el} key={`option-role-${index.toString()}`}>
            {el}
          </option>
        ))}
      </select>
    );
  }

  function getInputArray() {
    return (
      <div style={{ position: "relative" }}>
        <MDInput
          type="input"
          label={t("admin.customer")}
          fullWidth
          value={client}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (clients && clients.length > 0 && clients[elementInList]) {
                clientsSelected.push(clients[elementInList].id);
                setClient("");
                const newOriginals = [].concat(originalClients);
                const indexToDelete = newOriginals.findIndex(
                  (del) => del.id === clients[elementInList].id
                );
                if (indexToDelete >= 0) {
                  newOriginals.splice(indexToDelete, 1);
                }
                setOriginalClients(newOriginals);
                setClients(newOriginals);
              }
            } else if (e.key === "ArrowDown") {
              if (clients && elementInList < clients.length - 1) {
                setElementInList(elementInList + 1);
              }
            } else if (e.key === "ArrowUp") {
              if (elementInList > 0) {
                setElementInList(elementInList - 1);
              }
            }
          }}
          onChange={(e) => {
            setClient(e.target.value);
            const filterClients = [].concat(originalClients);
            setClients(
              filterClients.filter((el) =>
                el.name.toLowerCase().includes(e.target.value.toLowerCase())
              )
            );
          }}
          style={{ marginTop: "30px" }}
        />
        {client && client.length > MIN_CHAR_SEARCH && clients && clients.length > 0 ? (
          <div className="customDropdownContainer">
            {clients.map((el, index) => (
              // eslint-disable-next-line
              <div
                key={`client-dropdown-${index.toString()}`}
                className={`customDropdownClient ${index === elementInList ? "selected" : ""}`}
                onClick={() => {
                  clientsSelected.push(el.id);
                  setClient("");
                  const newOriginals = [].concat(originalClients);
                  const indexToDelete = newOriginals.findIndex((del) => del.id === el.id);
                  if (indexToDelete >= 0) {
                    newOriginals.splice(indexToDelete, 1);
                  }
                  // console.log("newOriginals", newOriginals);
                  // console.log("oldOriginals", originalClients);
                  setOriginalClients(newOriginals);
                  setClients(newOriginals);
                }}
              >
                {el.name}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    );
  }

  function viewArrayElements() {
    return clientsSelected.map((el, index) => {
      const indexToFind = allClients.findIndex((item) => item.id === el);
      let element = null;
      if (indexToFind >= 0) {
        element = allClients[indexToFind];
      }
      if (element) {
        return (
          <div key={`client-selected-el-${index.toString()}`} className="clientsViewArrayElement">
            {element.name}
            <IconButton
              onClick={() => {
                const newClientsSelected = [].concat(clientsSelected);
                newClientsSelected.splice(index, 1);
                setClientsSelected(newClientsSelected);
                const elementToRestore = allClients.find((restore) => restore.id === element.id);
                if (elementToRestore) {
                  const newOriginals = [].concat(originalClients);
                  newOriginals.push(elementToRestore);
                  setOriginalClients(newOriginals);
                  setClients(newOriginals);
                }
              }}
              size="small"
              disableRipple
            >
              <Icon fontSize="medium">close</Icon>
            </IconButton>
          </div>
        );
      }
      return null;
    });
  }

  const handleDeleteUser = async () => {
    try {
      await deleteUser(editElement.id);
      setDeleteUserPopup(false);
      setDeleteUserPopupSuccess(true);
      getUsers();
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {popup && editElement ? (
        <SweetAlert
          style={{ display: "block" }}
          title={editElement.name || "Edit user"}
          onConfirm={async () => {
            // TODO chiamata modifica utente
            const userToEdit = {
              name,
              email,
              role,
              clients: clientsSelected,
              shouldReceiveReport,
              hasMobile,
              mailMessageDisabled,
              skipMfa,
              mustChangePassword,
            };
            if (password) {
              Object.assign(userToEdit, { password });
            }
            if (!validateEmail(userToEdit.email)) {
              setInvalidEmail(true);
              return;
            }
            try {
              const res = await editUser(userToEdit, editElement.id);
              // eslint-disable-next-line
              console.log("resssss", res);
              const logToSend = {
                email: user && user.email ? user.email : "",
                type: `Edited user ${email}`,
                date: new Date(),
              };
              addLog(logToSend);
              setPopupSuccess(true);
              setEditElement(null);
              setName("");
              setEmail("");
              setRole("");
              setClient("");
              setPassword("");
              // setCreatedAt("");
              setShouldReceiveReport(false);
              setHasMobile(false);
              setMailMessageDisabled(false);
              setClients(originalClients);
              setElementInList(0);
              getUsers();
            } catch (err) {
              console.error("Error:", err.message);
              setUserEditError(true);
            } finally {
              setPopup(false);
              setInvalidEmail(false);
            }
          }}
          onCancel={() => {
            setPopup(false);
            setEditElement(null);
            setName("");
            setEmail("");
            setRole("");
            setPassword("");
            // setCreatedAt("");
            setClient("");
            setShouldReceiveReport(false);
            setHasMobile(false);
            setMailMessageDisabled(false);
            setClients(originalClients);
            setElementInList(0);
          }}
          confirmBtnText={t("save")}
          cancelBtnText={t("close")}
          showCancel
          // confirmBtnCssClass={classes.button + " " + classes.success}
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{
            boxShadow: "none",
          }}
          cancelBtnCssClass="popupCloseButton"
          cancelBtnStyle={{
            boxShadow: "none",
          }}
        >
          {getInput(t("admin.name"), setName, name)}
          <div style={{ position: "relative" }}>
            {getInput(t("admin.email"), setEmail, email)}
            {invalidEmail ? (
              <MDTypography color="error" fontSize="small" textAlign="left" position="absolute">
                {t("admin.invalid_email")}
              </MDTypography>
            ) : null}
          </div>
          {getInput(t("admin.password"), setPassword, password)}
          {getSelect(roles, setRole, role)}
          {getInputArray()}
          {clientsSelected && clientsSelected.length > 0 ? (
            <div className="clientsViewArrayContainer">{viewArrayElements()}</div>
          ) : null}
          <div style={{ textAlign: "left" }}>
            <input
              type="checkbox"
              style={{ marginBottom: "30px" }}
              checked={mailMessageDisabled}
              onChange={() => {
                setMailMessageDisabled(!mailMessageDisabled);
              }}
            />{" "}
            <span style={{ fontSize: "14px" }}>{t("admin.disable_mail_message")}</span>
          </div>
          <div style={{ textAlign: "left" }}>
            <input
              type="checkbox"
              style={{ marginBottom: "30px" }}
              checked={hasMobile}
              onChange={() => {
                setHasMobile(!hasMobile);
              }}
            />{" "}
            <span style={{ fontSize: "14px" }}>{t("admin.has_mobile")}</span>
          </div>
          <div style={{ textAlign: "left" }}>
            <input
              type="checkbox"
              style={{ marginBottom: "30px" }}
              checked={shouldReceiveReport}
              onChange={() => {
                setShouldReceiveReport(!shouldReceiveReport);
              }}
            />{" "}
            <span style={{ fontSize: "14px" }}>{t("admin.receives_periodic_reports")}</span>
          </div>
          <div style={{ textAlign: "left" }}>
            <input
              type="checkbox"
              style={{ marginBottom: "30px" }}
              checked={skipMfa}
              onChange={() => {
                setSkipMfa(!skipMfa);
              }}
            />{" "}
            <span style={{ fontSize: "14px" }}>{t("admin.skip_mfa")}</span>
          </div>
          <div style={{ textAlign: "left" }}>
            <input
              type="checkbox"
              style={{ marginBottom: "30px" }}
              checked={mustChangePassword}
              onChange={() => {
                setMustChangePassword(!mustChangePassword);
              }}
            />{" "}
            <span style={{ fontSize: "14px" }}>{t("admin.must_change_password")}</span>
          </div>
        </SweetAlert>
      ) : null}
      {popupSuccess ? (
        <SweetAlert
          style={{ display: "block" }}
          title={t("admin.user_updated")}
          success
          onConfirm={() => {
            setPopupSuccess(false);
          }}
          confirmBtnText="Ok"
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{
            boxShadow: "none",
          }}
        >
          {t("admin.user_updated_text")}
        </SweetAlert>
      ) : null}
      {genericError ? (
        <SweetAlert
          style={{ display: "block" }}
          title={t("admin.generic_error_title")}
          error
          onConfirm={() => {
            setGenericError(false);
          }}
          confirmBtnText="Ok"
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{
            boxShadow: "none",
          }}
        >
          {t("admin.generic_error_text")}
        </SweetAlert>
      ) : null}
      {userEditError ? (
        <SweetAlert
          style={{ display: "block" }}
          title={t("admin.error_popup_edit_user_title")}
          error
          onConfirm={() => {
            setUserEditError(false);
          }}
          confirmBtnText="Ok"
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{
            boxShadow: "none",
          }}
        >
          {t("admin.error_popup_edit_user_subtitle")}
        </SweetAlert>
      ) : null}
      {refreshUserMfa ? (
        <SweetAlert
          style={{ display: "block" }}
          title={t("admin.refresh_user_mfa_title")}
          showCancel
          onConfirm={() => {
            forceRefreshUserMfa(refreshUserMfa)
              .then((res) => {
                if (res) {
                  getUsers();
                  setPopupSuccess(true);
                  setRefreshUserMfa(null);
                } else {
                  setRefreshUserMfa(false);
                  setGenericError(true);
                }
              })
              .catch((err) => {
                // eslint-disable-next-line
                console.log("error while refreshing user mfa", err);
              });
          }}
          onCancel={() => {
            setRefreshUserMfa(null);
          }}
          confirmBtnText={t("yes")}
          cancelBtnText={t("cancel")}
          // confirmBtnCssClass={classes.button + " " + classes.success}
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{
            boxShadow: "none",
          }}
          cancelBtnCssClass="popupCloseButton"
          cancelBtnStyle={{
            boxShadow: "none",
          }}
        >
          {t("admin.refresh_user_mfa_text")}
        </SweetAlert>
      ) : null}
      {deleteUserPopupSuccess ? (
        <SweetAlert
          style={{ display: "block" }}
          title={t("user.success_popup_delete_user_title")}
          success
          onConfirm={() => {
            setDeleteUserPopupSuccess(false);
          }}
          confirmBtnText="Ok"
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{
            boxShadow: "none",
          }}
        >
          {t("user.success_popup_delete_user_text")}
        </SweetAlert>
      ) : null}
      <div style={{ display: "flex", marginTop: "30px", justifyContent: "space-between" }}>
        <a href="/adduser" className="add-user-client">
          <PersonAddAlt1Icon fontSize="large" />
        </a>
        <div style={{ lineHeight: "40px", marginRight: "15px" }}>
          {t("displayed")}:
          <select
            onChange={(e) => {
              setLimit(e.target.value);
            }}
            value={limit}
            className="selectDefault"
            style={{
              padding: "0.75rem",
              borderRadius: "0.375rem",
              borderColor: "#d2d6da",
              marginBottom: "30px",
              marginLeft: "25px",
            }}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
      </div>
      {users && users.length > 0 ? (
        <div className="table_users_list">
          <table style={{ border: "solid 1px #dedede" }}>
            <thead>
              <tr>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (orderBy === "email") {
                      setOrderBy("emailReverse");
                    } else {
                      setOrderBy("email");
                    }
                  }}
                >
                  {t("admin.email")}
                  {orderBy === "email" ? <Icon fontSize="medium">south</Icon> : null}
                  {orderBy === "emailReverse" ? <Icon fontSize="medium">north</Icon> : null}
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  {t("admin.customer")}
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "15%",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (orderBy === "role") {
                      setOrderBy("roleReverse");
                    } else {
                      setOrderBy("role");
                    }
                  }}
                >
                  {t("admin.role")}
                  {orderBy === "role" ? <Icon fontSize="medium">south</Icon> : null}
                  {orderBy === "roleReverse" ? <Icon fontSize="medium">north</Icon> : null}
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "30%",
                    textAlign: "center",
                  }}
                >
                  {t("admin.session")}
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {t("admin.verified_email")}
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {t("admin.two_factors")}
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {t("admin.app_user")}
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {t("edit")}
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {t("delete")}
                </th>
              </tr>
            </thead>
            <tbody style={{ fontSize: "14px", textAlign: "center" }}>
              <tr>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  <MDInput
                    type="input"
                    label={t("admin.email")}
                    fullWidth
                    onChange={(e) => {
                      setEmailFilter(e.target.value);
                    }}
                  />
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  /
                </th>
                <th
                  style={{
                    padding: "10px",
                    borderBottom: "solid 1px #dedede",
                    width: "15%",
                    textAlign: "center",
                  }}
                >
                  <MDInput
                    type="input"
                    label={t("admin.role")}
                    fullWidth
                    onChange={(e) => {
                      setRoleFilter(e.target.value);
                    }}
                  />
                </th>
                <th
                  style={{
                    padding: "10px",
                    borderBottom: "solid 1px #dedede",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  /
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  /
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  <select
                    onChange={(e) => {
                      setAppUserFilter(e.target.value);
                    }}
                    value={appUserFilter}
                    className="selectDefault"
                    style={{
                      padding: "0.75rem",
                      borderRadius: "0.375rem",
                      borderColor: "#d2d6da",
                    }}
                  >
                    {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                    <option value="">{""}</option>
                    <option value="true">{t("admin.yes")}</option>
                    <option value="false">{t("admin.no")}</option>
                  </select>
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  /
                </th>
              </tr>
              {users.map((el, index) => (
                <tr style={{ borderBottom: "solid 1px #dedede" }} key={`user-${index.toString()}`}>
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                    {el.email}
                  </td>
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                    {el.clients && el.clients.length > 0
                      ? el.clients.map((cli) =>
                          cli.name ? (
                            <div key={`user-client-${index.toString()}-${cli.name}`}>
                              {cli.name}
                            </div>
                          ) : null
                        )
                      : "-"}
                  </td>
                  {/* TODO select */}
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                    {el.role}
                  </td>
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                    <div className="user-list-session-cell-box">
                      {el.lastLogin ? (
                        <p>
                          {t("admin.last_login")}{" "}
                          <span>{moment(el.lastLogin).format("DD/MM/YYYY hh:mm:ss")}</span>
                        </p>
                      ) : null}
                      {el.lastTokenRefresh ? (
                        <p>
                          {t("admin.refresh_token")}{" "}
                          <span>{moment(el.lastTokenRefresh).format("DD/MM/YYYY hh:mm:ss")}</span>
                        </p>
                      ) : null}
                    </div>
                  </td>
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                    {el.isEmailVerified ? (
                      <Icon fontSize="medium">check</Icon>
                    ) : (
                      <Icon fontSize="medium">close</Icon>
                    )}
                  </td>
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                    {el.mfaEnabled ? (
                      <Icon fontSize="medium">check</Icon>
                    ) : (
                      <Icon fontSize="medium">close</Icon>
                    )}
                    {/* ADD ICON TO REFRESH MFA */}
                    <Tooltip title={t("refresh")} placement="top">
                      <IconButton
                        onClick={() => {
                          setRefreshUserMfa(el.id);
                        }}
                        className="default-button"
                        style={{
                          border: "none",
                          padding: "0",
                          backgroundColor: "transparent",
                          color: "#344767",
                          marginTop: "-15px",
                        }}
                        size="small"
                        disableRipple
                      >
                        <Icon style={{ marginLeft: "10px" }} fontSize="medium">
                          replay
                        </Icon>
                      </IconButton>
                    </Tooltip>
                  </td>
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                    {el.hasMobile ? (
                      <Icon fontSize="medium">check</Icon>
                    ) : (
                      <Icon fontSize="medium">close</Icon>
                    )}
                  </td>
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                    <IconButton
                      onClick={() => {
                        // console.log("el", el);
                        setPopup(true);
                        setEditElement(el);
                        setName(el.name);
                        setEmail(el.email);
                        setRole(el.role);
                        setShouldReceiveReport(el.shouldReceiveReport);
                        setSkipMfa(el.skipMfa || false);
                        setMustChangePassword(el.mustChangePassword || false);
                        setHasMobile(el.hasMobile);
                        setMailMessageDisabled(el.mailMessageDisabled);
                        setClientsSelected(el.clients ? el.clients.map((cli) => cli.id) : []);
                        if (el.clients && el.clients.length > 0) {
                          const newOriginals = [].concat(originalClients);
                          el.clients.forEach((clientInside) => {
                            const indexToDelete = newOriginals.findIndex(
                              (del) => del.id === clientInside
                            );
                            if (indexToDelete >= 0) {
                              newOriginals.splice(indexToDelete, 1);
                            }
                          });
                          setOriginalClients(newOriginals);
                          setClients(newOriginals);
                        }
                      }}
                      size="small"
                      disableRipple
                    >
                      <Icon fontSize="medium">edit</Icon>
                    </IconButton>
                  </td>
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                    <IconButton
                      onClick={() => {
                        setDeleteUserPopup(true);
                        setEditElement(el);
                      }}
                    >
                      <Icon fontSize="medium">delete</Icon>
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {deleteUserPopup ? (
            <SweetAlert
              style={{ display: "block" }}
              title={t("user.confirm_popup_delete_user_title")}
              onConfirm={handleDeleteUser}
              onCancel={() => {
                setDeleteUserPopup(false);
                setEditElement(null);
              }}
              confirmBtnText={t("confirm")}
              cancelBtnText={t("cancel")}
              showCancel
              confirmBtnCssClass="popupCloseButton"
              confirmBtnStyle={{
                boxShadow: "none",
              }}
              cancelBtnCssClass="popupCloseButton"
              cancelBtnStyle={{
                boxShadow: "none",
              }}
            >
              {t("user.confirm_popup_delete_user_text")}
            </SweetAlert>
          ) : null}
          {pages && pages > 1 ? <Pagination setPage={setPage} pages={pages} active={page} /> : null}
        </div>
      ) : null}
    </DashboardLayout>
  );
}

export default UserList;
