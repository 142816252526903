/**
 * Returns the host part of an URL.
 * Handles special schemas returning a description after the host.
 * @param {String} _url
 * @returns {String} host
 */
export default function getHostFromUrl(_url, forceSchema = null) {
  if (!_url || _url.trim().length <= 0) return "N/A";

  let url = _url.trim();

  try {
    if (forceSchema) url = `${forceSchema}://${url}`;

    const newUrl = new URL(url);
    if (!newUrl) {
      return url;
    }

    if (newUrl.protocol === "android:") {
      let newHost = newUrl.host;

      // fallback for Chrome not parsing Android schema correctly
      if (newHost?.length <= 0) {
        const pos = url.indexOf("==@");
        if (pos > 0) {
          newHost = url.substring(pos + 3).replace(/^\/+|\/+$/g, "");
        }
      }

      return `${newHost} (app)`;
    }

    if (!newUrl.host && newUrl.pathname?.startsWith("//")) {
      return newUrl.pathname.substring(2);
    }

    return newUrl.host || url;
  } catch (err) {
    console.log("Error getting host", err);
    if (!forceSchema) return getHostFromUrl(url, "https");

    return url;
  }
}
